export const useHttp = () => {
  let isLoading = false
  let errors = null

  const setIsLoading = (loading = false) => {
    isLoading = loading
  }
  const setError = (error = null) => {
    errors = error
  }

  const request = async (url, method = 'GET', body = null, headers = {}) => {
    setIsLoading(true)
    try {
      if (body && !(body instanceof FormData)) {
        body = JSON.stringify(body)
        headers['Content-Type'] = 'application/json'
      }
      let response = await fetch(url, {
        method: method,
        headers,
        body: body,
      })
      if (response.redirected) {
        response = await fetch(response.url, {
          method: method,
          headers,
          body: body,
        })
      }
      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Something wrong.')
      }

      setIsLoading(false)
      return data
    } catch (err) {
      setIsLoading(false)
      setError(err.message)
      throw err
    }
  }

  const clearError = () => setError(null)

  return {
    isLoading,
    request,
    errors,
    clearError,
  }
}
